<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Edit Record</h4>
        </div>
      </div>
    </div>
    <!-- <div v-if="assessment_result.length">
      {{ assessment_result }}
    </div> -->

    <div class="row">
      <div class="col-lg-12">
        <place-holder
          v-if="loading || !(employees.length && assessor.length)"
        ></place-holder>
        <div class="card">
          <div
            v-if="!loading && employees.length && assessor.length"
            class="card-body"
          >
            <form class="parsley-examples">
              <div class="row mb-3 mt-2">
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Module Name</label>
                    </div>
                    <div class="col-7">
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          asssessment_result.assessment_before_one_weeks
                            .monthly_plan.module.name
                        "
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Trainning Date</label>
                    </div>
                    <div class="col-7">
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          asssessment_result.assessment_before_one_weeks
                            .training_date
                        "
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Assessor Name</label>
                    </div>
                    <div class="col-7">
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          asssessment_result.assessment_before_one_weeks
                            .assessor.name
                        "
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Trainer Name</label>
                    </div>
                    <div class="col-7">
                      <!-- <template > -->
                      <!-- <input type="text" class="form-control" readonly v-model="data.user.name"> -->
                      <select
                        name=""
                        id=""
                        class="form-select"
                        v-for="(data, index) in asssessment_result
                          .assessment_before_one_weeks.monthly_plan.trainers"
                        :key="index"
                        disabled
                      >
                        <option value="">{{ data.user.name }}</option>
                      </select>
                      <!-- </template> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Department</label>
                    </div>
                    <div
                      class="col-7"
                      v-if="employees.length && assessor.length"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="getAssessorInformation[0].department[0].name"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Related Hr</label>
                    </div>
                    <div class="col-7">
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          asssessment_result.assessment_before_one_weeks
                            .hr_information.name
                        "
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Position</label>
                    </div>
                    <div
                      class="col-7"
                      v-if="employees.length && assessor.length"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          getAssessorInformation[0].position_level[0].name
                        "
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">State</label>
                    </div>
                    <div class="col-7">
                      <input
                        type="text"
                        class="form-control"
                        v-model="asssessment_result.state"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="d-flex h5"
                        ><div>Holding</div>
                        <div>Business</div></label
                      >
                    </div>
                    <div
                      class="col-7"
                      v-if="employees.length && assessor.length"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          getAssessorInformation[0].business_unit[0].name
                        "
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-3">
                      <label for="" class="h5">Status</label>
                    </div>
                    <div class="col-7">
                      <input
                        type="text"
                        class="form-control"
                        :value="checkStatus(asssessment_result.status)"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <template v-if="employees.length && assessor.length">
                <div
                  class="row"
                  v-for="(
                    data, index
                  ) in asssessment_result.assessment_result_answer"
                  :key="index"
                >
                  <template v-if="data.question_name.question_type == 'radio'">
                    <div class="col-9 mt-3">
                      <div class="row mb-2">
                        <div class="h5">{{ data.question_name.name }}</div>
                      </div>
                      <div class="row">
                        <div class="col-3 d-flex">
                          <input
                            type="radio"
                            class="custom-radio border-black"
                            :checked="
                              data.answer?.toLowerCase() == 'strongly agree'
                            "
                            :disabled="
                              !(data.answer?.toLowerCase() == 'strongly agree')
                            "
                          />
                          <div class="ms-2">Strongly Agree</div>
                        </div>
                        <div class="col-3 d-flex">
                          <input
                            type="radio"
                            class="custom-radio"
                            :checked="data.answer?.toLowerCase() == 'agree'"
                            :disabled="!(data.answer?.toLowerCase() == 'agree')"
                          />
                          <div class="ms-2">Agree</div>
                        </div>
                        <div class="col-3 d-flex">
                          <input
                            type="radio"
                            class="custom-radio"
                            :checked="data.answer?.toLowerCase() == 'disagree'"
                            :disabled="
                              !(data.answer?.toLowerCase() == 'disagree')
                            "
                          />
                          <div class="ms-2">Disagree</div>
                        </div>
                        <div class="col-3 d-flex">
                          <input
                            type="radio"
                            class="custom-radio"
                            :checked="
                              data.answer?.toLowerCase() == 'strongly disagree'
                            "
                            :disabled="
                              !(
                                data.answer?.toLowerCase() ==
                                'strongly disagree'
                              )
                            "
                          />
                          <div class="ms-2">Strongly Disagree</div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>

                <div
                  class="row"
                  v-for="(
                    data, index
                  ) in asssessment_result.assessment_result_answer"
                  :key="index"
                >
                  <template v-if="data.question_name.question_type == 'typing'">
                    <div class="col-9 mt-3">
                      <p class="text-black">
                        {{ data.question_name.name }}
                      </p>
                      <input
                        type="text"
                        class="form-control"
                        :value="data.answer"
                        readonly
                      />
                    </div>
                  </template>
                </div>

                <div class="col-9 mt-4">
                  <p class="h5">
                    If you choose Disagree and Strongly Disagree, please write
                    detailed reason.
                  </p>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="typing"
                    v-model="default_questions.target_mark_suggestion"
                    readonly
                  />
                </div>

                <div class="col-9 mt-4">
                  <p class="h5">Suggestion for training team.</p>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="typing"
                    v-model="default_questions.next_improved_title"
                    readonly
                  />
                </div>

                <div class="col-9 mt-4">
                  <p class="h5">
                    Next improved training titles for your subordinate.
                  </p>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="typing"
                    v-model="default_questions.team_suggestion"
                    readonly
                  />
                </div>
              </template>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";
import { options } from "dropzone";
import { data } from "jquery";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      asssessment_result: [],
      assessor: [],
      employees: [],
      trainers: [],
      default_questions: {},
      // assessor_information : getAssessorInformation(),
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  methods: {
    checkStatus(status) {
      if (status == 0) {
        return "Waiting";
      } else if (status == 1) {
        return "Confirmed";
      } else if (status == 2) {
        return "Drafted";
      }
      return;
    },
    async assessmentResultEdit() {
      this.loading = true;
      // this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/after-assessment-result/${this.$route.params.id}`
        )
        .then((res) => {
          this.asssessment_result = res.data.data[0];

          this.assessor.push(
            res.data.data[0].assessment_before_one_weeks.assessor
          );
          // console.log('assessor', this.asssessment_result);
          this.default_questions.target_mark_suggestion =
            this.asssessment_result.above_target_mark_suggestion;
          this.default_questions.next_improved_title =
            this.asssessment_result.next_improved_training_title;
          this.default_questions.team_suggestion =
            this.asssessment_result.team_suggestion;
          this.loading = false;
          // this.$Progress.finish();
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
          this.loading = false;
          this.$Progress.finish();
        });
    },
  },
  computed: {
    getAssessorInformation() {
      return this.employees.filter(
        (res) => this.assessor[0].employee_id == res.emp_id
      );
    },
  },
  validations() {
    return {
      module: {
        name: { required },
        duration: { required },
        question_list: [{ type: { required } }],
      },
    };
  },
  created() {
    this.assessmentResultEdit();
    let intervalId;
    if (!this.employees.length) {
      intervalId = setInterval(() => {
        this.employees = this.$store.getters["odoo/getAllEmployees"];
        // console.log('+++++running');
        // this.loading = true;
        if (this.employees.length) {
          // this.loading = false;
          clearInterval(intervalId);
        }
      }, 2000);
    }
  },
};
</script>

<style>
.del {
  color: rgb(126, 26, 26);
}
.del::hover {
  cursor: pointer;
}
.custom-radio {
  /* Increase the size of the radio button */
  transform: scale(1.5);
}
/* .custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
} */
</style>
